import React from 'react';
import { css, Global } from '@emotion/react';

/**
 * ### WithGlobalStyles
 *
 * * Contains global CSS variables (like FG and BG colors)
 * * Imports fonts from the static server
 * */
const globalCss = css`
  @font-face {
    font-family: 'Inter';
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/InterRegular.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 500;
    font-display: swap;
    src: url(/fonts/InterMedium.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 700;
    src: url(/fonts/InterBold.woff2) format('woff2');
  }

  body {
    -webkit-font-smoothing: antialiased;
    font-feature-settings: 'cv11' on;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
  }
`;
export const WithGlobalStyles: React.FC = () => {
  // to get assets url for font features support

  return <Global styles={globalCss} />;
};
